const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.muttley.forwoodsafety.com',
    USER_TOKEN_URL: 'https://zzb85l9910.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://n0kogdl8m1.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'feature/CV4-2354-admin-improve-auth-flow-to-read-jwt-token',
    HOSTNAME: 'https://admin.dev.muttley.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.dev.muttley.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.dev.muttley.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.muttley.forwoodsafety.com',
    WEBSOCKET: 'wss://3se1lnc7f3.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
