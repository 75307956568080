import { connect } from 'react-redux';
import {
  configServiceFetchRequest, wsConnect, wsDisconnect, authInit,
} from 'componentlibrary';
import App from '../../Components/App';
import { getAppConfig } from '../../reducers/appConfig';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  appConfig: state.appConfig,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getAppConfig(...args) {
    return getAppConfig(dispatch, ...args);
  },
  wsConnect: (value) => dispatch(wsConnect(value)),
  configServiceFetchRequest: (value) => dispatch(configServiceFetchRequest(value)),
  wsDisconnect: () => dispatch(wsDisconnect()),
  authInit: (payload) => dispatch(authInit(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
